@import vars
@import fonts
@import libs
@import reset

::placeholder
	color: #666

::selection
	background-color: $accent
	color: #fff

input, textarea
	outline: none
	&:focus:required:invalid
		border-color: red
	&:required:valid
		border-color: green

body
	font-size: 16px
	min-width: 320px
	position: relative
	line-height: 1.65
	font-family: $default-font, sans-serif
	overflow-x: hidden
	color: $text
	@media (max-width: 768px)
		font-size: 14px
.layer-container
	max-width: 1400px
	margin: 0 auto
header
	display: flex
	justify-content: space-around
	padding: 10px 0
	@media (max-width: 768px)
		justify-content: space-between
		padding: 15px
		align-items: center
.menu-list
	display: flex
	gap: 60px	
	list-style: none
	height: 100%
	a
		text-decoration: none
		color: #000000
		text-transform: uppercase
	@media (max-width: 768px)
		flex-direction: column
		gap: 7px
.menu-item 
	display: inline-flex
	align-items: center
.header-contacts
	display: flex
	gap: 60px
	.phone
		display: inline-flex
		align-items: center
		font-size: 20px
		@media (max-width: 768px)
			display: none
.btn
	display: inline-block
	text-decoration: none
	color: #000
	text-transform: uppercase
	padding: 5px 30px
	border: 2px solid #1B1310
	@media (max-width: 768px )
		padding: 5px 15px
		
.background
	background-image: url('../img/main-bg.jpg')
	background-size: cover
	padding-top: 90px
	height: 784px
	text-align: center
	color: #fff
	@media screen and ( max-width: 768px )
		background-image: url('../img/main-bg-small.jpg')
		height: calc( 100vh - 115px)
		box-sizing: border-box
	.main-title
		font-family: $title-font
		font-size: 70px
		margin: 0
		@media screen and ( max-width: 768px )
			font-size: 30px
			
	.title-span
		text-transform: uppercase
		font-size: 18px
		@media screen and ( max-width: 768px )
			font-size: 14px
			
	@media screen and ( max-width: 768px )
		padding-top: 30px
		
.description-and-slider
	margin: 80px 0
	@media screen and ( max-width: 768px )
		margin: 20px 0

// 	display: flex
.description-and-slider
	.description
		display: flex
		justify-content: space-around
		background-color: #ECEEF6
		max-width: 980px
		margin: 0 auto
		padding: 50px 0
		position: relative
		z-index: 5
		@media screen and ( max-width: 768px )
			flex-direction: column
			margin: 0 15px
			padding: 32px 15px
		.left
			flex-basis: 40%
			font-family: $title-font
			font-weight: bold
			font-size: 38px
			line-height: 1.25
			@media screen and ( max-width: 768px )
				font-size: 24px
				margin-bottom: 20px
		.right
			flex-basis: 45%
			font-size: 20px
			line-height: 1.45
			@media screen and ( max-width: 768px )
				font-size: 20px
				
.slider
	position: relative
	top: -35px
	background-image: url('../img/slider-bg.jpg')
	&::before
		content: ''
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		background: rgb(255,255,255)
		background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7) 100%)
	@media screen and ( max-width: 768px )
		top: 0
		
.swiper-slide
	img
		width: 100%
		height: auto
	@media screen and ( max-width: 768px )
		padding: 0 15px
		box-sizing: border-box

.swiper-button-next, .swiper-button-prev
	@media screen and ( max-width: 768px )
		display: none
.swiper-pagination-bullet 
	width: 12px
	height: 12px
		
.features-section
	max-width: 1110px
	margin: 0 auto
	@media screen and ( max-width: 768px )
		margin: 0 15px
		margin-top: 30px
		
.features-block
	display: flex
	flex-wrap: wrap
	justify-content: space-between
	gap: 60px
	@media screen and ( max-width: 768px )
		gap: 25px
		
.feature-block
	max-width: 510px
	flex: 1
	flex-basis: 100%
	img
		@media screen and ( max-width: 768px )
			max-width: 100%
.feature-title
	h3
		margin: 5px 0
.bedroom-block, .kitchen-block
	.image-wrapper
		flex-basis: 49%
		&:first-child
			flex-basis: 100%
	img
		@media screen and ( max-width: 768px )
			max-width: 100%
	.image-block
		display: flex
		flex-wrap: wrap
		justify-content: space-between
	.feature-title
		h3
			font-family: $title-font
			font-size: 36px
			margin: 15px 0
			@media screen and ( max-width: 768px )
				font-size: 24px
				margin: 5px 0
				
	.desc-item
		font-size: 18px
		list-style-type: disc
		list-style-position: inside
		@media screen and ( max-width: 768px )
			font-size: 16px
			
.map
	margin-top: 50px
	iframe
		box-sizing: border-box
		@media screen and ( max-width: 768px )
			height: 320px
			
footer
	background-color: #324247
	padding: 65px 0
	font-size: 20px
	color: #fff
	.wrapper
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		gap: 10px
		max-width: 1110px
		margin: 0 auto
	.address
		position: relative
		margin-bottom: 30px
		&::before
			content: ''
			position: absolute
			top: 0
			height: 34px
			width: 34px
			background-image: url('../img/icons/loc.svg')
			background-size: cover
			@media screen and ( max-width: 768px )
				height: 28px
				width: 28px
		@media screen and ( max-width:  768px)
			margin-bottom: 15px
			
				
		p
			margin-left: 40px
	.right-col
		span
			white-space: nowrap
	@media screen and ( max-width: 768px )
		padding: 15px
		font-size: 16px
		
	.phone
		position: relative
		&::before
			content: ''
			position: absolute
			top: 0
			height: 34px
			width: 34px
			background-image: url('../img/icons/phone.svg')
			background-size: cover
			@media screen and ( max-width: 768px )
				height: 28px
				width: 28px
		p
			margin-left: 40px

// Custom Sass / Remove before use
